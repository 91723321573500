import React from 'react'
import { IBainSvg } from './types'

const Radial = ({ width = '18', height = '19', style = {} }: IBainSvg) => (
  <svg
    width={width}
    height={height}
    style={{ ...style }}
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.15574 18.1866C13.8426 18.1866 17.642 14.3871 17.642 9.70029C17.642 5.01344 13.8426 1.21399 9.15574 1.21399C4.46888 1.21399 0.669434 5.01344 0.669434 9.70029C0.669434 14.3871 4.46888 18.1866 9.15574 18.1866Z"
      fill="white"
      stroke="#696E72"
      strokeWidth="0.658363"
      strokeLinejoin="round"
    />
  </svg>
)
export default Radial
