import React from 'react'
import {
  Grid,
  TAGTypography,
  Card,
  CardContent,
  useTheme,
  useMediaQuery,
  CardActions,
  TAGLink,
} from '@aspendental/design-system'

interface IPolicyCardProps {
  brandLogo: string
  logoStyles?: React.CSSProperties
  descriptionBody1: string
  descriptionBody2?: string
  practiceLink?: string
  privacyLink: string
  termsLink: string
}

const PolicyCard = ({
  brandLogo,
  logoStyles = {},
  descriptionBody1,
  descriptionBody2,
  practiceLink,
  privacyLink,
  termsLink,
}: IPolicyCardProps) => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Grid container>
      <Grid item>
        <Card sx={{ width: isSmall ? '339px' : '410px', boxShadow: 'none' }}>
          <img src={brandLogo} alt="Brand logo" style={{ ...logoStyles }} />
          <CardContent sx={{ padding: '16px 0' }}>
            <TAGTypography sx={{ color: 'text.secondary', fontSize: '12px', lineHeight: '16px' }}>
              {descriptionBody1}
            </TAGTypography>
            {descriptionBody2 && (
              <>
                <br />
                <br />
                <TAGTypography sx={{ color: 'text.secondary', fontSize: '12px', lineHeight: '16px', mt: '20px' }}>
                  {descriptionBody2}
                  {practiceLink && (
                    <TAGLink href={practiceLink} color="text.link" sx={{ fontSize: 'inherit' }}>
                      click here
                    </TAGLink>
                  )}
                  .
                </TAGTypography>
              </>
            )}

            <CardActions sx={{ pl: 0, pr: 0, pb: 0 }}>
              <TAGLink href={privacyLink} color="text.link" sx={{ fontSize: '12px', lineHeight: '16px' }}>
                Privacy Policy
              </TAGLink>
              <TAGTypography sx={{ ml: '10px' }}> | </TAGTypography>
              <TAGLink href={termsLink} color="text.link" sx={{ fontSize: '12px', lineHeight: '16px' }}>
                Terms of Use
              </TAGLink>
            </CardActions>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default PolicyCard
