import React from 'react'
import { IBainSvg } from '../../../SVGs/types'

const ADLogoMobile = ({ width = '94', height = '17' }: IBainSvg) => (
  <svg width={width} height={height} viewBox="0 0 94 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1394_467)">
      <path
        d="M33.562 4.80774C30.755 4.80774 29.1422 6.543 29.1422 9.51336C29.1422 12.4837 30.9898 14.1577 33.9805 14.2394C35.3789 14.27 36.5324 13.923 37.5225 13.5351C37.5531 13.5249 37.5735 13.4943 37.5735 13.4636V11.4426C37.5735 11.4222 37.5531 11.4017 37.5327 11.4017C37.5225 11.4017 37.5225 11.4017 37.5123 11.4017C36.5834 11.8815 35.3585 12.1673 34.5317 12.1673C33.0006 12.1673 32.0513 11.6875 31.8778 10.432H37.8696C37.89 10.432 37.9104 10.4116 37.9104 10.3912V9.87062C37.9104 7.18607 36.9101 4.80774 33.562 4.80774ZM31.837 8.66614C31.9084 7.6352 32.4392 6.78798 33.5518 6.78798C34.7869 6.78798 35.2667 7.6352 35.2667 8.66614H31.837Z"
        fill="#002855"
      />
      <path
        d="M44.0246 4.97095C42.6057 4.97095 41.6769 5.39966 41.0644 6.696H41.0338V5.11385C41.0338 5.07302 41.0032 5.03219 40.9521 5.03219H38.7269C38.6861 5.03219 38.6453 5.06282 38.6453 5.11385V13.9535C38.6453 13.9943 38.6759 14.0351 38.7269 14.0351H41.2277C41.2686 14.0351 41.3094 14.0045 41.3094 13.9535V10.2278C41.3094 8.29857 41.9729 7.41052 43.2488 7.41052C44.096 7.41052 44.8105 7.98214 44.8105 9.67657V13.9535C44.8105 13.9943 44.8412 14.0351 44.8922 14.0351H47.393C47.4338 14.0351 47.4747 14.0045 47.4747 13.9535V8.56396C47.4645 6.696 46.4744 4.97095 44.0246 4.97095Z"
        fill="#002855"
      />
      <path
        d="M16.291 8.3496C14.8926 8.04338 14.668 7.89027 14.668 7.48197C14.668 7.02264 15.1172 6.74704 15.8929 6.74704C16.6687 6.74704 17.4138 6.92057 18.2712 7.29824C18.3019 7.30845 18.3325 7.29824 18.3427 7.26762C18.3427 7.25741 18.3529 7.2472 18.3529 7.2472V5.22613C18.3529 5.16489 18.3223 5.11385 18.2712 5.09344C17.5363 4.84846 16.6891 4.75659 15.699 4.75659C13.2084 4.75659 11.7589 5.88961 11.7589 7.67591C11.7589 9.18661 12.5755 9.90113 14.4843 10.3401C15.9542 10.6871 16.1787 10.83 16.1787 11.2791C16.1787 11.7997 15.6479 11.9937 14.9334 11.9937C14.0556 11.9834 13.1982 11.8201 12.3816 11.5037C12.3509 11.4935 12.3203 11.5037 12.3101 11.5343C12.3101 11.5445 12.3101 11.5445 12.3101 11.5547L12.3407 13.5962C12.3407 13.6473 12.3816 13.6983 12.4326 13.7085C13.29 13.9126 14.1679 14.0249 15.0457 14.0249C17.5567 14.0249 19.1593 12.9532 19.1593 11.0546C19.1389 9.11516 17.7609 8.67624 16.291 8.3496Z"
        fill="#002855"
      />
      <path
        d="M8.20679 4.29728L6.79817 0.683848C6.59402 0.163269 6.0122 -0.0817089 5.49162 0.12244C5.23644 0.224514 5.03229 0.428662 4.93021 0.683848L3.91968 3.27653H4.3586C6.4307 3.28674 8.10472 4.24624 8.20679 4.29728Z"
        fill="#002855"
      />
      <path
        d="M9.66641 8.05365C8.76815 5.77739 6.57356 4.28711 4.12378 4.28711H3.52154L0.0408087 13.2084C-0.0714729 13.5248 0.0816384 13.8821 0.398069 13.9944C0.459313 14.0148 0.530765 14.0352 0.602217 14.0352H2.28644C2.43955 14.0352 2.58246 13.9433 2.63349 13.8004L3.41947 11.8406C3.4603 11.7385 3.56237 11.6671 3.67465 11.6671H8.04343C8.15571 11.6671 8.25778 11.7385 8.29861 11.8406L9.07438 13.8004C9.13562 13.9433 9.26832 14.0352 9.42143 14.0352H11.1057C11.4425 14.0352 11.7079 13.7698 11.7079 13.433C11.7079 13.3615 11.6977 13.2901 11.6671 13.2186L9.66641 8.05365ZM7.03289 9.55414H4.69539C4.55249 9.55414 4.44021 9.44186 4.44021 9.30916C4.44021 9.27854 4.45042 9.24792 4.46062 9.2173L5.5324 6.51233C5.60385 6.32859 5.808 6.24693 5.98153 6.31839C6.0734 6.34901 6.14485 6.42046 6.17547 6.51233L7.24725 9.2173C7.29829 9.34999 7.23704 9.4929 7.10435 9.54393C7.08393 9.55414 7.06351 9.55414 7.03289 9.55414Z"
        fill="#002855"
      />
      <path
        d="M24.9572 5.03219C23.7527 5.03219 22.7626 5.61402 22.1195 6.5531V5.05261C22.1195 5.01178 22.0889 4.97095 22.0378 4.97095H19.7616C19.7208 4.97095 19.6799 5.00157 19.6799 5.05261V16.4951C19.6799 16.536 19.7106 16.5768 19.7616 16.5768H22.2726C22.3134 16.5768 22.3543 16.5462 22.3543 16.4951V12.6367C23.0178 13.4329 23.9364 13.9127 24.947 13.9127C27.213 13.9127 28.5706 12.0243 28.5706 9.28869C28.5808 7.01243 27.2845 5.03219 24.9572 5.03219ZM24.161 11.8814C23.1913 11.8814 22.3849 10.8913 22.3849 9.56429C22.3849 8.2067 23.0994 7.19617 24.161 7.19617C25.294 7.19617 25.9167 8.24753 25.9167 9.4418C25.9269 10.8913 25.2634 11.8814 24.161 11.8814Z"
        fill="#002855"
      />
      <path
        d="M88.5903 8.66607C88.5903 6.27753 87.5798 5.03223 85.0585 5.03223C84.0378 5.04243 83.0374 5.32824 82.1698 5.85903L82.2617 7.23703C82.915 6.68583 83.8949 6.33878 84.8135 6.33878C86.4365 6.33878 86.9877 7.11454 86.9877 8.62524C86.3549 8.59462 85.8955 8.59462 85.2627 8.59462C83.5886 8.59462 81.1797 9.28872 81.1797 11.4935C81.1797 13.4023 82.5067 14.2291 84.4052 14.2291C85.8853 14.2291 86.7427 13.4227 87.1204 12.8817H87.151V14.0148H88.6719C88.6311 13.7596 88.6005 13.29 88.6005 12.2999L88.5903 8.66607ZM86.9673 10.6157C86.9673 11.9222 86.1201 12.9226 84.5175 12.9226C83.7724 12.9226 82.8843 12.4224 82.8843 11.4935C82.8843 9.942 85.0483 9.8093 85.9261 9.8093C86.2732 9.8093 86.61 9.83992 86.9571 9.83992L86.9673 10.6157Z"
        fill="#002855"
      />
      <path
        d="M78.5971 11.3507V6.54298H80.9244V5.23643H78.5971V2.89893L76.9741 3.41951V5.23643H74.9939V6.54298H76.9741V11.6773C76.9741 13.433 78.0051 14.2292 79.6791 14.2292C80.1997 14.2292 80.6896 14.0863 81.0571 13.9842V12.6062C80.7305 12.8103 80.3426 12.9226 79.9547 12.9124C79.2096 12.9226 78.5971 12.3714 78.5971 11.3507Z"
        fill="#002855"
      />
      <path d="M91.4075 2.81726H89.7947V14.025H91.4075V2.81726Z" fill="#002855" />
      <path
        d="M71.8705 5.03223C70.6456 5.03223 69.4819 5.63446 68.992 6.63479H68.9613V5.23638H67.3894V14.0148H69.0124V9.95221C69.0124 7.81885 69.8392 6.33878 71.6867 6.33878C73.0953 6.43064 73.5445 7.42076 73.5445 9.47246V14.0148H75.1572V8.79877C75.1674 6.45106 74.1467 5.03223 71.8705 5.03223Z"
        fill="#002855"
      />
      <path
        d="M51.9966 3.00098H48.8016V14.0352H51.9966C55.559 14.0352 57.7535 12.1264 57.7535 8.42112C57.7535 5.03225 55.2017 3.00098 51.9966 3.00098ZM51.9761 12.647H50.3532V4.39939H52.1599C54.3851 4.39939 56.1102 5.91009 56.1102 8.49257C56.1102 11.3302 54.6403 12.647 51.9761 12.647Z"
        fill="#002855"
      />
      <path
        d="M62.6124 5.03223C60.132 5.03223 58.4988 6.86956 58.4988 9.47245C58.4988 12.3305 59.9278 14.2291 63.0513 14.2291C64.5007 14.2291 65.2969 13.8412 65.9196 13.6167V12.0039C65.5215 12.3305 64.1843 12.9226 63.1125 12.9226C61.5202 12.9328 60.234 11.6466 60.2238 10.0645C60.2238 10.0441 60.2238 10.0237 60.2238 10.0032H66.4912C66.481 7.16558 65.5419 5.03223 62.6124 5.03223ZM60.2238 8.78856C60.3463 7.65553 60.9996 6.33878 62.5817 6.33878C63.9802 6.33878 64.7763 7.44118 64.7763 8.78856H60.2238Z"
        fill="#002855"
      />
      <path
        d="M92.1731 3.19492C92.071 3.1745 91.9689 3.14388 91.9689 3.0316C91.9689 2.91932 92.0506 2.87849 92.1629 2.87849C92.2751 2.87849 92.3568 2.92952 92.3568 3.0316H92.4691C92.4589 2.86828 92.3262 2.78662 92.1629 2.78662C91.9995 2.78662 91.8668 2.86828 91.8668 3.0316C91.8668 3.19492 92.0097 3.24596 92.1424 3.27658C92.2343 3.29699 92.367 3.31741 92.367 3.4399C92.367 3.53176 92.2853 3.5828 92.1833 3.5828C92.071 3.59301 91.9689 3.52155 91.9587 3.40927C91.9587 3.39907 91.9587 3.38886 91.9587 3.37865H91.8464C91.8464 3.55218 91.9791 3.68487 92.1527 3.68487C92.1629 3.68487 92.1731 3.68487 92.1833 3.68487C92.3466 3.68487 92.4895 3.59301 92.4895 3.42969C92.4895 3.26637 92.3058 3.22554 92.1731 3.19492Z"
        fill="#002855"
      />
      <path
        d="M93.3163 2.81726L93.0611 3.50116L92.7957 2.81726H92.6426V3.67468H92.7447V2.96016L93.01 3.67468H93.0917L93.3673 2.96016V3.67468H93.4694V2.81726H93.3163Z"
        fill="#002855"
      />
    </g>
    <defs>
      <clipPath id="clip0_1394_467">
        <rect width="93.5" height="16.6381" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
export default ADLogoMobile
