import React from 'react'
import { IBainSvg } from './types'

const RadialCheck = ({ width = '19', height = '19', style = {} }: IBainSvg) => (
  <svg
    width={width}
    height={height}
    style={{ ...style }}
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.4863 18.1866C14.1732 18.1866 17.9726 14.3871 17.9726 9.70029C17.9726 5.01344 14.1732 1.21399 9.4863 1.21399C4.79945 1.21399 1 5.01344 1 9.70029C1 14.3871 4.79945 18.1866 9.4863 18.1866Z"
      fill="white"
      stroke="#142E54"
      strokeWidth="0.658363"
      strokeLinejoin="round"
    />
    <path
      d="M4.1272 11.4648L7.47168 14.3221C7.92595 14.7105 8.60407 14.6578 8.98592 14.2036L14.977 7.20515C15.3655 6.75088 15.3128 6.07277 14.8585 5.68433L14.1409 5.07206C13.6866 4.6902 13.0151 4.74287 12.6267 5.19056L8.66332 9.81885C8.28147 10.2665 7.60336 10.3258 7.14909 9.94394L6.14179 9.08807C5.68752 8.70622 5.01599 8.75889 4.62755 9.21316C4.42346 9.45017 4.2062 9.70693 4.00211 9.94394C3.62026 10.3982 3.67293 11.0697 4.12061 11.4582L4.1272 11.4648Z"
      fill="#B9DEFA"
      stroke="#002D55"
      strokeWidth="0.658363"
      strokeMiterlimit="10"
    />
  </svg>
)
export default RadialCheck
