import React, { FC } from 'react'
import { Grid, TAGTypography, useTheme, useMediaQuery } from '@aspendental/design-system'
import desktopFinancing from '../../images/desktop/financing.png'
import mobileFinancing from '../../images/mobile/financing.png'

export const FinancialMessage: FC = () => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('md'))
  const cardInfo = {
    cardTitle: 'Financing made easy',
    description:
      'Wherever your new smile journey takes you, ClearChoice and Aspen Dental help connect you with third-party lenders who provide easy financing solutions—so you can afford the cost of the right solution for you.',
  }
  const singleMessageStyle = isSmall
    ? {
        width: '339px',
        height: '320px',
        titleSize: '24px',
        iconSize: 52,
        iconMarginTop: '20px',
        titleFontSize: '24px',
        titleMarginBtm: '10px',
      }
    : {
        width: '410px',
        height: '354px',
        titleSize: 'none',
        iconSize: 61,
        iconMarginTop: '36px',
        titleFontSize: '28px',
        titleMarginBtm: '25px',
      }
  const financingLogo = isSmall ? mobileFinancing : desktopFinancing

  return (
    <Grid
      container
      direction="column"
      sx={{
        width: singleMessageStyle.width,
        height: singleMessageStyle.height,
        backgroundColor: 'backgrounds.neutral',
        borderRadius: '8px',
      }}
    >
      <Grid item container justifyContent="center" sx={{ mt: singleMessageStyle.iconMarginTop }}>
        <Grid item>
          <img src={financingLogo} alt="Financing logo" />
        </Grid>
      </Grid>
      <Grid item container justifyContent="center">
        <Grid item>
          <TAGTypography
            variant="h2"
            sx={{
              color: 'text.primary',
              fontSize: singleMessageStyle.titleFontSize,
              mb: singleMessageStyle.titleMarginBtm,
            }}
          >
            {cardInfo.cardTitle}
          </TAGTypography>
        </Grid>
      </Grid>
      <Grid item container justifyContent="center">
        <Grid xs={9} md={8} lg={9} item textAlign="center">
          <TAGTypography variant={'bodyMediumBook'} sx={{ color: 'text.secondary' }}>
            {cardInfo.description}
          </TAGTypography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FinancialMessage
