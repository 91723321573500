import React, { FC } from 'react'
import { Grid, TAGTypography, useTheme, useMediaQuery } from '@aspendental/design-system'
import StarIcon from '@mui/icons-material/Star'

export const Review: FC = () => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const reviewData = {
    title: 'Changing lives, one smile at a time',
    review: `"If you’re considering implants, do it. Owe it to yourself….it’s worth it. It's a long-term solution. There's less shifting. They don't fall out of your mouth."`,
    author: 'Implant Patient Review',
    date: 'Dec 27, 2020',
  }

  const mobileStyling = isSmall
    ? {
        titleSize: '22px',
        titleHeight: '30px',
        starHeight: '19px',
        starWidth: '18px',
        reviewSize: '15px',
        reviewHeight: '24px',
        authorSize: '12px',
        authorHeight: '20px',
        dateSize: '12px',
        dateHeight: '20px',
      }
    : {
        titleSize: 'none',
        titleHeight: 'none',
        starHeight: '26px',
        starWidth: '28px',
        reviewSize: '22px',
        reviewHeight: '30px',
        authorSize: 'none',
        authorHeight: 'none',
        dateSize: 'none',
        dateHeight: 'none',
      }

  return (
    <Grid container justifyContent="center" spacing={1} direction="column" sx={{ margin: '50px auto 0' }}>
      <Grid justifyContent="center" item container>
        <Grid item xs={7} textAlign="center">
          <TAGTypography
            variant="h2"
            sx={{
              color: 'text.primary',
              fontSize: mobileStyling.titleSize,
              lineHeight: mobileStyling.titleHeight,
              textAlign: 'center',
            }}
          >
            {reviewData.title}
          </TAGTypography>
        </Grid>
      </Grid>
      <Grid item justifyContent="center" container>
        <Grid item>
          <StarIcon sx={{ height: mobileStyling.starHeight, width: mobileStyling.starWidth, color: 'accent01.dark' }} />
          <StarIcon sx={{ height: mobileStyling.starHeight, width: mobileStyling.starWidth, color: 'accent01.dark' }} />
          <StarIcon sx={{ height: mobileStyling.starHeight, width: mobileStyling.starWidth, color: 'accent01.dark' }} />
          <StarIcon sx={{ height: mobileStyling.starHeight, width: mobileStyling.starWidth, color: 'accent01.dark' }} />
          <StarIcon sx={{ height: mobileStyling.starHeight, width: mobileStyling.starWidth, color: 'accent01.dark' }} />
        </Grid>
      </Grid>
      <Grid justifyContent="center" item container>
        <Grid item xs={8} sm={7} md={6} lg={5} xl={4} textAlign="center">
          <TAGTypography
            variant="captionLargeRegular"
            sx={{
              fontSize: mobileStyling.reviewSize,
              lineHeight: mobileStyling.reviewHeight,
              textAlign: 'center',
              color: 'text.secondary',
            }}
          >
            {reviewData.review}
          </TAGTypography>
        </Grid>
      </Grid>
      <Grid justifyContent="center" item container sx={{ mt: '10px' }}>
        <Grid item>
          <TAGTypography
            variant="bodySmallSemiBold"
            sx={{ fontSize: mobileStyling.authorSize, lineHeight: mobileStyling.authorHeight }}
          >
            {reviewData.author}
          </TAGTypography>
          <TAGTypography
            variant="captionLargeRegular"
            sx={{ fontSize: mobileStyling.dateSize, lineHeight: mobileStyling.dateHeight }}
          >
            {' -'} {reviewData.date}
          </TAGTypography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Review
