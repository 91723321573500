import React, { FC } from 'react'
import {
  Box,
  Card,
  CardContent,
  Grid,
  TAGTypography,
  TAGSvgIcon,
  useTheme,
  useMediaQuery,
} from '@aspendental/design-system'

import { Fit, Function, Time, Cost, Radial, RadialCheck } from '../../assets/SVGs/index'
import { descriptionCardsData } from './CardsData'

export const DescriptionCards: FC = () => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('md'))
  const isInBetweenMediumAndLarge = useMediaQuery(theme.breakpoints.between('md', 'lg'))
  const getRadioIconsBasedOnInfo = (
    cardInfo: { quality: string; implants: string; teeth: string },
    title: 'quality' | 'implants' | 'teeth',
    option: string,
  ) =>
    cardInfo[title] === option ? (
      <RadialCheck
        style={{ position: 'relative', top: '4px', marginRight: isInBetweenMediumAndLarge ? '2px' : '5px' }}
      />
    ) : (
      <Radial style={{ position: 'relative', top: '4px', marginRight: isInBetweenMediumAndLarge ? '2px' : '5px' }} />
    )

  return isSmall ? null : (
    <Grid container justifyContent="center" spacing={3} sx={{ margin: 'auto' }}>
      {descriptionCardsData.map(card => (
        <Grid key={card.id} item>
          <Card
            raised
            elevation={5}
            sx={{
              width: isInBetweenMediumAndLarge ? '200px' : '238px',
              height: '100%',
              backgroundColor: card.background,
              boxShadow: '0px 1.33px 1.33px rgba(0, 0, 0, 0.25)',
              borderRadius: '8px',
            }}
          >
            <CardContent sx={{ display: 'flex', flexDirection: 'column', padding: '13px' }}>
              <Box sx={{ mb: '15px' }}>
                <TAGTypography variant="bodySmallSemiBold">Quality:</TAGTypography>
                <Box display="flex" sx={{ borderTop: '1px solid', borderColor: 'neutrals.primary' }}>
                  <Box minWidth="45%">
                    {getRadioIconsBasedOnInfo(card.info, 'quality', 'premium')}
                    <TAGTypography color="text.secondary" variant="bodySmallBook">
                      Premium
                    </TAGTypography>
                  </Box>
                  <Box minWidth="55%">
                    {getRadioIconsBasedOnInfo(card.info, 'quality', 'standard')}
                    <TAGTypography color="text.secondary" variant="bodySmallBook">
                      Standard
                    </TAGTypography>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ mb: '15px' }}>
                <TAGTypography variant="bodySmallSemiBold">Implants:</TAGTypography>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  sx={{ borderTop: '1px solid', borderColor: 'neutrals.primary' }}
                >
                  <Box minWidth="45%">
                    {getRadioIconsBasedOnInfo(card.info, 'implants', 'yes')}
                    <TAGTypography color="text.secondary" variant="bodySmallBook">
                      Yes
                    </TAGTypography>
                  </Box>
                  <Box minWidth="55%">
                    {getRadioIconsBasedOnInfo(card.info, 'implants', 'no')}
                    <TAGTypography color="text.secondary" variant="bodySmallBook">
                      No
                    </TAGTypography>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ mb: '15px' }}>
                <TAGTypography variant="bodySmallSemiBold">Teeth:</TAGTypography>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  sx={{ borderTop: '1px solid', borderColor: 'neutrals.primary' }}
                >
                  <Box minWidth="45%">
                    {getRadioIconsBasedOnInfo(card.info, 'teeth', 'fixed')}
                    <TAGTypography color="text.secondary" variant="bodySmallBook">
                      Fixed
                    </TAGTypography>
                  </Box>
                  <Box minWidth="55%">
                    {getRadioIconsBasedOnInfo(card.info, 'teeth', 'removable')}
                    <TAGTypography color="text.secondary" variant="bodySmallBook">
                      Removable
                    </TAGTypography>
                  </Box>
                </Box>
              </Box>
            </CardContent>
            <CardContent>
              <Box display="flex" flexDirection="column" sx={{ marginBottom: '30px' }}>
                {/* Tooth arrows icon */}
                <Box display="flex" justifyContent="center">
                  <Fit width="54" height="54" />
                </Box>
                <TAGTypography
                  color="text.secondary"
                  variant="bodySmallBold"
                  sx={{ display: 'flex', justifyContent: 'center' }}
                >
                  Fit
                </TAGTypography>
                <TAGTypography
                  color="text.secondary"
                  variant="bodySmallBook"
                  sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center', height: '80px' }}
                >
                  {card.fit}
                </TAGTypography>
              </Box>
              <Box display="flex" flexDirection="column" sx={{ marginBottom: '30px' }}>
                {/* Tooth Gear icon */}
                <Box display="flex" justifyContent="center">
                  <Function width="32" height="32" />
                </Box>
                <TAGTypography
                  color="text.secondary"
                  variant="bodySmallBold"
                  sx={{ display: 'flex', justifyContent: 'center' }}
                >
                  Function
                </TAGTypography>
                <TAGTypography
                  color="text.secondary"
                  variant="bodySmallBook"
                  sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center', height: '60px' }}
                >
                  {card.function}
                </TAGTypography>
              </Box>
              <Box display="flex" alignItems="center" flexDirection="column" sx={{ marginBottom: '30px' }}>
                {/* Clock icon */}
                <Box display="flex" justifyContent="center">
                  <Time width="32" height="32" />
                </Box>
                <TAGTypography
                  color="text.secondary"
                  variant="bodySmallBold"
                  sx={{ display: 'flex', justifyContent: 'center' }}
                >
                  Longevity
                </TAGTypography>
                <TAGTypography
                  color="text.secondary"
                  variant="bodySmallBook"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    textAlign: 'center',
                    height: '40px',
                    width: '80%',
                  }}
                >
                  {card.longevity}
                </TAGTypography>
              </Box>
              <Box display="flex" flexDirection="column" sx={{ marginBottom: '30px' }}>
                {/* Dentures icon */}
                <Box display="flex" justifyContent="center">
                  <TAGSvgIcon icon="Dentures" />
                </Box>
                <TAGTypography
                  color="text.secondary"
                  variant="bodySmallBook"
                  sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center', height: '40px' }}
                >
                  {card.dentures}
                </TAGTypography>
              </Box>
              <Box display="flex" flexDirection="column">
                {/* Pig Coin icon */}
                <Box display="flex" justifyContent="center">
                  <Cost width="32" height="32" />
                </Box>
                <TAGTypography
                  color="text.secondary"
                  variant="bodyLargeBold"
                  sx={{ display: 'flex', justifyContent: 'center', fontSize: '22px', lineHeight: '32px' }}
                >
                  {card.dollars}
                </TAGTypography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  )
}

export default DescriptionCards
