import React from 'react'
import { IBainSvg } from './types'

const Close = ({ width = '34', height = '34' }: IBainSvg) => (
  <svg width={width} height={height} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M25.7833 7.93335L8.21668 25.5M7.93335 7.93335L25.5 25.5" stroke="black" />
    <circle cx="17" cy="17" r="16.5" stroke="black" />
  </svg>
)
export default Close
