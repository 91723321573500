module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NTW7353","includeInDevelopment":true,"enableWebVitalsTracking":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby","app_name":"aspendental-clearchoice-compare","app_version":"1.0.5","ds_version":"0.19.3","build_date":"2022-12-16T23:15:49.386Z"}},"routeChangeEventName":"gatsby_page_view","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PLB42T","includeInDevelopment":true,"enableWebVitalsTracking":true,"routeChangeEventName":"gatsby_page_view","defaultDataLayer":null,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Web @ Aspen - Micro Frontend Template","short_name":"aspendental-clearchoice-compare","start_url":"/aspen-clearchoice","background_color":"#f7f0eb","theme_color":"#a2466c","display":"standalone","orientation":"any","icon":"src/images/bain-favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f78c9f6547eaaeaa86db996b63c32413"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
