import React, { FC } from 'react'
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Fab,
  Grid,
  IconButton,
  TAGTypography,
  TAGSvgIcon,
} from '@aspendental/design-system'
import { useTheme } from '@mui/material/styles'
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import { descriptionCardsData } from './CardsData'
import SwipeableViews, { SwipeableViewsProps } from 'react-swipeable-views'
import { Cost, Fit, Function, Time, Radial, RadialCheck, Close } from '../../assets/SVGs/index'

export interface ICardsDialog {
  id: number
  open: boolean
  handleClose: () => void
  handleStepChange: (actionType: 'CHANGE' | 'NEXT' | 'BACK', id?: number) => void
}

const CardsDialog: FC<ICardsDialog> = ({ id: activeStep, open, handleClose, handleStepChange }) => {
  const theme = useTheme()
  const SWIPABLE_PROPS: SwipeableViewsProps = {
    axis: theme.direction === 'rtl' ? 'x-reverse' : 'x',
    index: activeStep,
    onChangeIndex: activeStep => handleStepChange('CHANGE', activeStep),
    enableMouseEvents: true,
  }
  //                                 1               quality         premium
  const getRadioIconsBasedOnInfo = (
    cardInfo: { quality: string; implants: string; teeth: string },
    title: 'quality' | 'implants' | 'teeth',
    option: string,
  ) => (cardInfo[title] === option ? <RadialCheck /> : <Radial />)

  const checkListStyle = {
    height: '60px',
    borderTop: '1px solid',
    borderColor: 'neutrals.primary',
    pt: '8px',
  }
  const detailsStyling = {
    borderTop: '1px dashed',
    borderTopColor: 'neutrals.primary',
    py: '24px',
  }
  return (
    <>
      <Dialog
        scroll="paper"
        PaperProps={{ square: true }}
        open={open}
        onClose={handleClose}
        sx={{
          '.MuiDialog-paper': {
            margin: '0px',
            maxWidth: '450px',
          },
        }}
      >
        <SwipeableViews {...(SWIPABLE_PROPS as unknown)}>
          {descriptionCardsData.map(card => (
            <DialogContent
              key={card.id}
              sx={[
                {
                  padding: '0px',
                  '.MuiPaper-root': { background: 'linear-gradient(180deg, #FFFFFF 97%, #F2F3F4 99% )' },
                },
              ]}
            >
              <Card square>
                <CardMedia component="img" src={card.image} sx={{ height: '231px', width: '100%' }} />
                <CardHeader
                  title={
                    <TAGTypography
                      variant="bodySmallSemiBold"
                      sx={{ display: 'flex', justifyContent: 'center', fontSize: '11px', lineHeight: '13px' }}
                    >
                      {card.dentureType}
                    </TAGTypography>
                  }
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: 'warning.dark',
                    height: '30px',
                    padding: '0px',
                  }}
                />
                <CardContent className="TitleHeader-Section" sx={{ padding: '16px 0px 2px 0px' }}>
                  <Grid container direction="column">
                    <Grid item justifyContent="center" container sx={{ mb: '20px' }}>
                      <Grid item xs={7.5} textAlign="center">
                        <TAGTypography textAlign="center" variant="h2" sx={{ fontSize: '28px', lineHeight: '32px' }}>
                          {card.title}
                        </TAGTypography>
                      </Grid>
                    </Grid>
                    <Grid item justifyContent="center" container>
                      <Grid item xs={8} textAlign="center">
                        <TAGTypography textAlign="center" variant="bodySmallBook" sx={{ color: 'text.tertiary' }}>
                          {card.header}
                        </TAGTypography>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardContent className="Checklist-Section">
                  <Grid container direction="column">
                    <Grid item sx={checkListStyle}>
                      <TAGTypography
                        variant="bodySmallSemiBold"
                        color="text.secondary"
                        sx={{ display: 'flex', justifyContent: 'row' }}
                      >
                        Quality:
                        <Box display="flex" flexDirection="row" sx={{ width: '100%' }}>
                          <Box sx={{ ml: '62px' }}>{getRadioIconsBasedOnInfo(card.info, 'quality', 'premium')}</Box>
                          <TAGTypography variant="bodySmallBook" color="text.secondary" sx={{ ml: '8px' }}>
                            Premium
                          </TAGTypography>
                          <Box sx={{ ml: '30px' }}>{getRadioIconsBasedOnInfo(card.info, 'quality', 'standard')}</Box>
                          <TAGTypography variant="bodySmallBook" color="text.secondary" sx={{ ml: '8px' }}>
                            Standard
                          </TAGTypography>
                        </Box>
                      </TAGTypography>
                    </Grid>
                    <Grid item sx={checkListStyle}>
                      <TAGTypography variant="bodySmallSemiBold" sx={{ display: 'flex', justifyContent: 'row' }}>
                        Implants:
                        <Box display="flex" flexDirection="row" sx={{ width: '100%' }}>
                          <Box sx={{ ml: '50px' }}>{getRadioIconsBasedOnInfo(card.info, 'implants', 'yes')}</Box>
                          <TAGTypography variant="bodySmallBook" sx={{ ml: '8px' }}>
                            Yes
                          </TAGTypography>
                          <Box sx={{ ml: '65px' }}>{getRadioIconsBasedOnInfo(card.info, 'implants', 'no')}</Box>
                          <TAGTypography variant="bodySmallBook" sx={{ ml: '8px' }}>
                            No
                          </TAGTypography>
                        </Box>
                      </TAGTypography>
                    </Grid>
                    <Grid item sx={checkListStyle}>
                      <TAGTypography variant="bodySmallSemiBold" sx={{ display: 'flex', justifyContent: 'row' }}>
                        Teeth:
                        <Box display="flex" flexDirection="row" sx={{ width: '100%' }}>
                          <Box sx={{ ml: '70px' }}>{getRadioIconsBasedOnInfo(card.info, 'teeth', 'fixed')}</Box>
                          <TAGTypography variant="bodySmallBook" sx={{ ml: '8px' }}>
                            Fixed
                          </TAGTypography>
                          <Box sx={{ ml: '54px' }}>{getRadioIconsBasedOnInfo(card.info, 'teeth', 'removable')}</Box>
                          <TAGTypography variant="bodySmallBook" sx={{ ml: '8px' }}>
                            Removable
                          </TAGTypography>
                        </Box>
                      </TAGTypography>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardContent className="Details-Section" sx={{ mt: '-25px' }}>
                  <Box display="flex" flexDirection="row" sx={detailsStyling}>
                    <Box display="flex" flexDirection="column" justifyContent="center" sx={{ mr: '20px' }}>
                      <Fit width="68" height="28" />
                    </Box>
                    <Box display="flex" flexDirection="column">
                      <TAGTypography variant="tabActive">Fit</TAGTypography>
                      <Grid item xs={10}>
                        <TAGTypography variant="bodySmallBook" color="text.tertiary">
                          {card.fit}
                        </TAGTypography>
                      </Grid>
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection="row" sx={detailsStyling}>
                    <Box display="flex" flexDirection="column" justifyContent="center" sx={{ ml: '15px', mr: '35px' }}>
                      <Function width="43" height="49" />
                    </Box>
                    <Box display="flex" flexDirection="column">
                      <TAGTypography variant="tabActive">Function</TAGTypography>
                      <Grid item xs={9}>
                        <TAGTypography variant="bodySmallBook" color="text.tertiary">
                          {card.function}
                        </TAGTypography>
                      </Grid>
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection="row" sx={detailsStyling}>
                    <Box display="flex" flexDirection="column" justifyContent="center" sx={{ ml: '15px', mr: '35px' }}>
                      <Time height="46" width="46" />
                    </Box>
                    <Box display="flex" flexDirection="column">
                      <TAGTypography variant="tabActive">Longevity</TAGTypography>
                      <Grid item xs={9}>
                        <TAGTypography variant="bodySmallBook" color="text.tertiary">
                          {card.longevity}
                        </TAGTypography>
                      </Grid>
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection="row" sx={detailsStyling}>
                    <Box display="flex" flexDirection="column" justifyContent="center" sx={{ ml: '15px', mr: '35px' }}>
                      <TAGSvgIcon icon="Dentures" size={49} />
                    </Box>
                    <Box display="flex" flexDirection="column">
                      <TAGTypography variant="tabActive">Bite strength</TAGTypography>
                      <Grid item xs={9}>
                        <TAGTypography variant="bodySmallBook" color="text.tertiary">
                          {card.dentures}
                        </TAGTypography>
                      </Grid>
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection="row" sx={detailsStyling}>
                    <Box display="flex" flexDirection="column" justifyContent="center" sx={{ ml: '15px', mr: '35px' }}>
                      <Cost width="53" height="63" />
                    </Box>
                    <Box display="flex" flexDirection="column">
                      <TAGTypography variant="tabActive">Cost</TAGTypography>
                      <Grid item xs={9}>
                        <TAGTypography variant="bodySmallBook" color="text.tertiary">
                          {card.dollars}
                        </TAGTypography>
                      </Grid>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </DialogContent>
          ))}
        </SwipeableViews>
        <DialogActions
          sx={{
            display: 'flex',
            flexDirection: 'column',
            py: '10px',
            '.MuiTypography-root': {
              lineHeight: '32px',
              fontSize: '20px',
              mb: '18px',
            },
          }}
        >
          <TAGTypography variant="h2">See other solutions</TAGTypography>
          <Box>
            {
              <Fab
                disabled={activeStep == 0 ? true : false}
                onClick={() => handleStepChange('BACK')}
                size="medium"
                sx={{ backgroundColor: 'backgrounds.white', mr: '20px' }}
              >
                <ArrowBackIosRoundedIcon sx={{ color: activeStep == 0 ? 'text.disabled' : 'text.link' }} />
              </Fab>
            }
            {
              <Fab
                disabled={activeStep == descriptionCardsData.length - 1 ? true : false}
                onClick={() => handleStepChange('NEXT')}
                size="medium"
                sx={{ backgroundColor: 'backgrounds.white' }}
              >
                <ArrowForwardIosRoundedIcon
                  sx={{ color: activeStep == descriptionCardsData.length - 1 ? 'text.disabled' : 'text.link' }}
                />
              </Fab>
            }
          </Box>
        </DialogActions>
        <Box sx={{ width: 'fit-content', position: 'absolute', top: '12px', right: '10px', zIndex: '1' }}>
          <IconButton disableRipple onClick={handleClose} sx={{ p: '0px' }}>
            <Close />
          </IconButton>
        </Box>
      </Dialog>
    </>
  )
}
export default CardsDialog
