import React from 'react'
import { IBainSvg } from './types'

const Time = ({ width = '36', height = '35' }: IBainSvg) => (
  <svg width={width} height={height} viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.9211 34.4716C27.2252 34.4716 34.7676 26.9292 34.7676 17.6251C34.7676 8.32101 27.2252 0.778564 17.9211 0.778564C8.61703 0.778564 1.07458 8.32101 1.07458 17.6251C1.07458 26.9292 8.61703 34.4716 17.9211 34.4716Z"
      fill="white"
      stroke="#142E54"
      strokeLinejoin="round"
    />
    <path d="M17.7904 18.8405L13.3991 15.9914" stroke="#142E54" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M17.8558 18.8667L23.5932 11.7831" stroke="#142E54" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M16.6926 4.42495C16.6926 3.73227 17.2284 3.18335 17.9211 3.18335C18.5877 3.18335 19.1496 3.74534 19.1496 4.42495C19.1496 5.09149 18.5877 5.65348 17.9211 5.65348C17.2284 5.65348 16.6926 5.09149 16.6926 4.42495V4.42495Z"
      fill="#142E54"
    />
    <path
      d="M16.6926 30.9951C16.6926 30.3025 17.2284 29.7535 17.9211 29.7535C18.5877 29.7535 19.1496 30.3155 19.1496 30.9951C19.1496 31.6617 18.5877 32.2237 17.9211 32.2237C17.2284 32.2237 16.6926 31.6617 16.6926 30.9951Z"
      fill="#142E54"
    />
    <path
      d="M30.0626 17.6382C30.0626 16.9455 30.5985 16.3966 31.2912 16.3966C31.9577 16.3966 32.5197 16.9585 32.5197 17.6382C32.5197 18.3047 31.9577 18.8667 31.2912 18.8667C30.5985 18.8667 30.0626 18.3047 30.0626 17.6382Z"
      fill="#142E54"
    />
    <path
      d="M3.24411 17.6382C3.24411 16.9455 3.77996 16.3966 4.47264 16.3966C5.13918 16.3966 5.70117 16.9585 5.70117 17.6382C5.70117 18.3047 5.13918 18.8667 4.47264 18.8667C3.77996 18.8667 3.24411 18.3047 3.24411 17.6382Z"
      fill="#142E54"
    />
  </svg>
)
export default Time
