import React, { FC } from 'react'
import { Box, Grid, AppBar, useTheme, useMediaQuery } from '@aspendental/design-system'
import ClearChoiceLogo from '../../assets/logos/Desktop/ClearChoiceDentalImplantsLogo.png'
import AspenLogo from '../../assets/logos/Desktop/AspenDentalLogo.png'
import { ADLogoMobile, CCLogoMobile } from '../../assets/logos/Mobile/SVGs'

export const BainAppBar: FC = () => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('md'))
  const appBarRender = isSmall ? { barHeight: '72px ' } : { barHeight: '88px' }
  const desktopBarStyle = {
    clearChoiceLogoHeight: '45px',
    clearChoiceLogoWidth: '153px',
    marginClearChoice: '21px 0px 0px 24px',
    aspenLogoHeight: '52px',
    aspenLogoWidth: '200px',
    marginAspen: '18px 14px 0px 0px',
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <AppBar
          position="static"
          elevation={0}
          sx={{
            backgroundColor: 'neutrals.white',
            height: appBarRender.barHeight,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          {isSmall ? (
            <Box sx={{ mt: '28px', ml: '11px' }}>
              <CCLogoMobile />
            </Box>
          ) : (
            <Box
              sx={{
                backgroundImage: `url(${ClearChoiceLogo})`,
                width: desktopBarStyle.clearChoiceLogoWidth,
                height: desktopBarStyle.clearChoiceLogoHeight,
                margin: desktopBarStyle.marginClearChoice,
              }}
            />
          )}
          {isSmall ? (
            <Box sx={{ mt: '40px', mr: '16px' }}>
              <ADLogoMobile />
            </Box>
          ) : (
            <Box
              sx={{
                backgroundImage: `url(${AspenLogo})`,
                width: desktopBarStyle.aspenLogoWidth,
                height: desktopBarStyle.aspenLogoHeight,
                margin: desktopBarStyle.marginAspen,
              }}
            />
          )}
        </AppBar>
      </Grid>
    </Grid>
  )
}

export default BainAppBar
