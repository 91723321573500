import React from 'react'
import { IBainSvg } from './types'

const Fit = ({ width = '69', height = '29' }: IBainSvg) => (
  <svg width={width} height={height} viewBox="0 0 69 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1394_984)">
      <path
        d="M35.0842 1.44792C36.4739 1.44792 37.8635 1.31265 39.2286 1.05439C40.2616 0.857631 41.0732 0.697761 41.0732 0.697761C41.848 0.550188 42.635 0.796142 43.1884 1.34954L47.7263 5.88739C48.3658 6.52687 48.5871 7.4861 48.2797 8.34694L44.6518 26.0556C44.5904 26.5352 44.3936 26.978 44.0739 27.3346C43.1392 28.3799 41.393 28.3307 40.3722 27.4084C40.2739 27.3223 40.2001 27.2239 40.114 27.1255C39.6221 26.5107 36.6829 21.6039 35.6499 19.8084C35.3917 19.3534 34.7522 19.3534 34.4816 19.8084C33.4486 21.6039 30.5095 26.4984 30.0176 27.1255C29.9438 27.2239 29.8577 27.3223 29.7593 27.4084C28.7509 28.3307 26.9923 28.3799 26.0577 27.3346C25.738 26.978 25.5412 26.5352 25.4797 26.0556L21.8519 8.34694C21.5444 7.4861 21.7658 6.53917 22.4053 5.88739L26.9431 1.34954C27.4965 0.796142 28.2959 0.550188 29.0583 0.697761L30.8907 1.05439C32.2681 1.32494 33.6577 1.44792 35.0473 1.44792H35.0842Z"
        fill="white"
        stroke="#002D55"
        strokeWidth="1.22977"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5193 13.721L11.3374 6.40388C11.0792 6.14563 10.7103 6.03495 10.3536 6.13333C9.99699 6.21942 9.72644 6.50226 9.62806 6.8466C9.52968 7.20323 9.62806 7.57216 9.88631 7.84271L15.3342 13.389L1.86821 13.266C1.58536 13.2414 1.30251 13.3398 1.09345 13.5243C0.859793 13.721 0.736816 13.9916 0.736816 14.2867C0.736816 14.5819 0.847496 14.8401 1.05656 15.0369C1.26562 15.2336 1.54847 15.332 1.83131 15.3197L15.2973 15.4427L9.75104 20.8906C9.49279 21.1488 9.38211 21.5178 9.48049 21.8744C9.56657 22.231 9.84942 22.5016 10.1938 22.6C10.5381 22.6983 10.9193 22.6 11.1899 22.3417L18.507 15.1599C18.7038 14.9754 18.8144 14.7048 18.8144 14.4343C18.8144 14.1637 18.7161 13.9055 18.5193 13.7087V13.721Z"
        fill="#002854"
      />
      <path
        d="M51.2067 15.0984L58.45 22.3418C58.7083 22.6 59.0895 22.6984 59.4461 22.6123C59.8028 22.514 60.0733 22.2434 60.1717 21.8868C60.2701 21.5301 60.1717 21.1612 59.9012 20.8907L54.4041 15.3936H67.8701C68.1529 15.4182 68.4358 15.3198 68.6448 15.123C68.8539 14.9263 68.9769 14.6557 68.9769 14.3729C68.9769 14.09 68.8539 13.8195 68.6448 13.6227C68.4358 13.4259 68.1529 13.3276 67.8701 13.3522H54.4041L59.9012 7.85508C60.1594 7.59683 60.2578 7.2156 60.1717 6.85897C60.0733 6.50233 59.8028 6.23178 59.4461 6.1334C59.0895 6.03502 58.7206 6.1334 58.45 6.40395L51.2067 13.6473C51.0099 13.8441 50.9115 14.1023 50.9115 14.3729C50.9115 14.6434 51.0222 14.9017 51.2067 15.0984Z"
        fill="#002854"
      />
    </g>
    <defs>
      <clipPath id="clip0_1394_984">
        <rect width="68.24" height="28.6783" fill="white" transform="translate(0.736816 0.0336914)" />
      </clipPath>
    </defs>
  </svg>
)
export default Fit
