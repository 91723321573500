import React, { FC } from 'react'
import { BainAppBar, Hero, Informational, Review } from './index'
import { TAGThemeProvider } from '@aspendental/design-system'

export const Bain: FC = () => {
  return (
    <TAGThemeProvider branding="aspendental">
      <BainAppBar />
      <Hero />
      <Review />
      <Informational />
    </TAGThemeProvider>
  )
}

export default Bain
