import Permanent from '../../assets/images/Desktop/Permanent.png'
import Transitional from '../../assets/images/Desktop/Transitional.png'
import Hybrid from '../../assets/images/Desktop/Hybrid.png'
import Affordable from '../../assets/images/Desktop/Affordable.png'
import PermanentMobile from '../../assets/images/Mobile/Hero/PermanentMobile.png'
import TransitionalMobile from '../../assets/images/Mobile/Hero/TransitionalMobile.png'
import HybridMobile from '../../assets/images/Mobile/Hero/HybridMobile.png'
import AffordableMobile from '../../assets/images/Mobile/Hero/AffordableMobile.png'
import PermanentDialog from '../../assets/images/Mobile/Dialog/PermanentDialog.gif'
import TransitionalDialog from '../../assets/images/Mobile/Dialog/TransitionalDialog.gif'
import HybridDialog from '../../assets/images/Mobile/Dialog/HybridDialog.gif'
import AffordableDialog from '../../assets/images/Mobile/Dialog/AffordableDialog.png'
export const dentureCardsData = [
  {
    id: 0,
    header: 'PERMANENT SOLUTION',
    image: Permanent,
    imageMobile: PermanentMobile,
    title: 'Dental implants with premium teeth',
    description:
      'The next best thing to real teeth. Titanium dental implants with our strongest zirconia teeth that feel like your own.',
    ctaText: 'SCHEDULE CONSULTATION',
    ctaUrl: 'https://www.clearchoice.com/schedule-consultation',
    cta_tag_name: 'schedule_consultation',
    cta_tag_value: 'permanent',
    period: '25+ years',
    cost: '$$$$',
    mobileDetails: {
      quality: 'Premium',
      teeth: 'Fixed',
      type: 'Implants',
    },
  },
  {
    id: 1,
    header: 'TRANSITIONAL SOLUTION',
    image: Transitional,
    imageMobile: TransitionalMobile,
    title: 'Dental implants with standard teeth',
    description: 'A smart way to start implants. The same titanium dental implants with acrylic teeth.',
    ctaText: 'SCHEDULE CONSULTATION',
    ctaUrl: 'https://www.clearchoice.com/schedule-consultation',
    cta_tag_name: 'schedule_consultation',
    cta_tag_value: 'transitional',
    period: '25+ years*',
    cost: '$$$',
    mobileDetails: {
      quality: 'Standard',
      teeth: 'Fixed',
      type: 'Implants',
    },
  },
  {
    id: 2,
    header: 'HYBRID SOLUTION',
    image: Hybrid,
    imageMobile: HybridMobile,
    title: 'Implant-supported denture',
    description:
      'Snaps in, stays in (until you take it out). A high-quality denture that securely snaps onto titanium dental implants.',
    ctaText: 'SCHEDULE CONSULTATION',
    ctaUrl: 'https://www.aspendental.com/schedule-an-appointment',
    cta_tag_name: 'schedule_consultation',
    cta_tag_value: 'hybrid',
    period: '25+ years*',
    cost: '$$',
    mobileDetails: {
      quality: 'Premium',
      teeth: 'Removable',
      type: 'Implants',
    },
  },
  {
    id: 3,
    header: 'AFFORDABLE SOLUTION',
    image: Affordable,
    imageMobile: AffordableMobile,
    title: 'Conventional denture',
    description:
      'The quickest way to renew your smile. A high-quality denture with value in mind. Convenient, reliable, affordable.',
    ctaText: 'SCHEDULE CONSULTATION',
    ctaUrl: 'https://www.aspendental.com/schedule-an-appointment',
    cta_tag_name: 'schedule_consultation',
    cta_tag_value: 'affordable',
    period: '5-7 years',
    cost: '$',
    mobileDetails: {
      quality: 'Standard',
      teeth: 'Removable',
      type: 'Denture',
    },
  },
]

export const descriptionCardsData = [
  {
    id: 0,
    image: PermanentDialog,
    dentureType: 'PERMANENT SOLUTION',
    title: 'Dental implants with premium teeth',
    header:
      'The next best thing to real teeth. Titanium dental implants with our strongest zirconia teeth that feel like your own.',
    background: 'backgrounds.light',
    info: {
      quality: 'premium',
      implants: 'yes',
      teeth: 'fixed',
    },
    fit: 'Permanently affixed with implants that are surgically placed by a specialist.',
    function: 'Feels like your real teeth. Brush and clean with floss or water flosser.',
    longevity: 'Implants and teeth: 25+ years',
    dentures: 'Up to 90% of your natural chewing ability',
    dollars: '$$$$',
  },
  {
    id: 1,
    image: TransitionalDialog,
    dentureType: 'TRANSITIONAL SOLUTION',
    title: 'Dental implants with standard teeth',
    header: 'A smart way to start implants. The same titanium dental implants with acrylic teeth.',
    background: 'backgrounds.white',
    info: {
      quality: 'standard',
      implants: 'yes',
      teeth: 'fixed',
    },
    fit: 'Permanently affixed with implants that are surgically placed by a specialist.',
    function: 'A close match to your real teeth. Brush and clean with floss or water flosser.',
    longevity: 'Implants: 25 years+ Teeth: 12-24 months',
    dentures: 'Up to 90% of your natural chewing ability',
    dollars: '$$$',
  },
  {
    id: 2,
    image: HybridDialog,
    dentureType: 'HYBRID SOLUTION',
    title: 'Implant-supported denture',
    header:
      'Snaps in, stays in (until you take it out). A high-quality denture that securely snaps onto titanium dental implants.',
    background: 'backgrounds.light',
    info: {
      quality: 'premium',
      implants: 'yes',
      teeth: 'removable',
    },
    fit: 'Snaps on and off implants that are surgically placed by a specially trained dentist.',
    function: 'Remove and clean daily before snapping it back into place.',
    longevity: 'Implants: 25 years+ Teeth: 12-24 months',
    dentures: 'Up to 60% of your natural chewing ability',
    dollars: '$$',
  },
  {
    id: 3,
    image: AffordableDialog,
    dentureType: 'AFFORDABLE SOLUTION',
    title: 'Conventional denture',
    header:
      'The quickest way to renew your smile. A high-quality denture with value in mind. Convenient, reliable, affordable.',
    background: 'backgrounds.white',
    info: {
      quality: 'standard',
      implants: 'no',
      teeth: 'removable',
    },
    fit: 'Stays in place with gel or adhesive. Highly customizable to ensure a comfortable, natural fit.',
    function: 'Remove and clean daily. Crafted to look like your own smile.',
    longevity: 'Teeth: up to 7 years',
    dentures: 'Up to 20% of your natural chewing ability',
    dollars: '$',
  },
]
