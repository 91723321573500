import React, { FC } from 'react'
import FinancialMessage from './FinancialMessage'
import CardRight from './CardRight'
import { Grid, useTheme, useMediaQuery } from '@aspendental/design-system'
import PolicyCard from './PolicyCard'
import ClearChoiceDesktop from '../../images/desktop/clearchoice_blue_logo.png'
import AspenBlueDesktop from '../../images/desktop/aspen_blue_logo.png'
import ClearChoiceMobile from '../../images/mobile/clearchoice_blue_logo.png'
import AspenBlueMobile from '../../images/mobile/aspen_blue_logo.png'
import {
  AspenPolicyBody1,
  AspenPolicyBody2,
  AspenPracticeLink,
  AspenPrivacyLink,
  AspenTermsLink,
  ClearChoicePolicyBody1,
  ClearChoicePolicyBody2,
  ClearChoicePrivacyLink,
  ClearChoiceTermsLink,
} from '../../constants/BrandPolicyContent'

export const InformationalCards: FC = () => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('md'))
  const clearChoiceLogo = isSmall ? ClearChoiceMobile : ClearChoiceDesktop
  const aspenBlueLogo = isSmall ? AspenBlueMobile : AspenBlueDesktop

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignContent="center"
        spacing={isSmall ? 3 : 6}
        sx={{ mt: '20px' }}
        direction={isSmall ? 'column' : 'row'}
      >
        <Grid item>
          <FinancialMessage />
        </Grid>
        <Grid item>
          <CardRight />
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="center"
        alignItems={isSmall ? 'center' : 'flex-start'}
        spacing={isSmall ? 3 : 7}
        flexDirection={isSmall ? 'column' : 'row'}
        sx={{ mt: '0px', mb: '30px' }}
      >
        <Grid item>
          <PolicyCard
            brandLogo={clearChoiceLogo}
            descriptionBody1={ClearChoicePolicyBody1}
            descriptionBody2={ClearChoicePolicyBody2}
            privacyLink={ClearChoicePrivacyLink}
            termsLink={ClearChoiceTermsLink}
          />
        </Grid>
        <Grid item>
          <PolicyCard
            brandLogo={aspenBlueLogo}
            logoStyles={!isSmall ? { position: 'relative', right: '8px' } : undefined}
            descriptionBody1={AspenPolicyBody1}
            descriptionBody2={AspenPolicyBody2}
            practiceLink={AspenPracticeLink}
            privacyLink={AspenPrivacyLink}
            termsLink={AspenTermsLink}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default InformationalCards
