import React, { FC, useState } from 'react'
import { useLocation } from '@reach/router'
import {
  Box,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Grid,
  TAGButton,
  TAGTypography,
  TAGLink,
  useTheme,
  useMediaQuery,
} from '@aspendental/design-system'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { dentureCardsData } from './CardsData'
import CardsDialog from './CardsDialog'

export interface IMobileDetails {
  card: {
    id: number
    mobileDetails: {
      quality: string
      teeth: string
      type: string
    }
    period: string
    cost: string
  }
}

export const DentureCards: FC = () => {
  const theme = useTheme()
  const { search: queryParams } = useLocation()
  const [open, setOpen] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'))
  const isInBetweenMediumAndLarge = useMediaQuery(theme.breakpoints.between('md', 'lg'))
  const mobileCards = isMobile
    ? {
        height: '265px',
        width: '100%',
        headerWidth: '145px',
        imageHeight: '100%',
        direction: 'row',
        textAlignment: 'left',
        buttonText: 'BOOK CONSULTATION',
        buttonHeight: '35px',
        alignedItems: 'baseline',
        paddingRight: '24px',
        cardWidth: '100%',
        cardRadius: '10px',
        cardBorder: '1px solid',
        headerPadding: '5px',
        titleWidth: 12,
      }
    : {
        height: '100%',
        width: isInBetweenMediumAndLarge ? '200px' : '235px',
        headerWidth: 'inherit',
        imageHeight: '212px',
        direction: 'column',
        textAlignment: 'center',
        buttonText: 'SCHEDULE CONSULTATION',
        buttonHeight: '64px',
        alignedItems: 'center',
        paddingRight: '0',
        cardWidth: 'auto',
        cardRadius: '6px',
        cardBorder: '',
        headerPadding: '3px',
        titleWidth: 11,
      }
  const contentFlow = (id: number) => {
    switch (true) {
      case isMobile && id % 2 == 1:
        return 'row-reverse'
      case isMobile && id % 2 == 0:
        return 'row'
      default:
        return 'column'
    }
  }
  const handleOpen = (id: number) => {
    setOpen(true)
    setActiveStep(id)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const handleStepChange = (actionType: string, step = 0) => {
    switch (actionType) {
      case 'CHANGE':
        return setActiveStep(step)
      case 'NEXT':
        return setActiveStep(prevActiveStep => prevActiveStep + 1)
      case 'BACK':
        return setActiveStep(prevActiveStep => prevActiveStep - 1)
    }
  }
  const MobileDetails: FC<IMobileDetails> = ({ card }) => {
    return (
      <>
        <ul style={{ marginBottom: '5px' }}>
          <li>
            <TAGTypography textAlign="center" variant="bodySmallBook" color="text.secondary">
              {card.mobileDetails.quality}
            </TAGTypography>
          </li>
          <li>
            <TAGTypography textAlign="center" variant="bodySmallBook" color="text.secondary">
              {card.mobileDetails.teeth}
            </TAGTypography>
          </li>
          <li>
            <TAGTypography textAlign="center" variant="bodySmallBook" color="text.secondary">
              {card.mobileDetails.type}
            </TAGTypography>
          </li>
        </ul>
        <Box display="flex" flexDirection="column">
          <TAGTypography variant="bodySmallBook" color="text.secondary">
            Cost: {card.cost}
          </TAGTypography>
          <TAGLink
            onClick={() => handleOpen(card.id)}
            variant="bodySmallSemiBold"
            color="text.link"
            sx={{ width: 'fit-content', fontSize: '12px', letterSpacing: 0.75 }}
          >
            LEARN MORE
            <ArrowRightIcon sx={{ position: 'relative', top: '7.5px' }} />
          </TAGLink>
        </Box>
      </>
    )
  }

  const onScheduleConsultationHandler = (url: string, tagName: string, tagValue: string) => {
    // ATTENTION!!!! do not open a new window or tab with the url
    // This is a requirement to maintain tracking
    const appendedUrl = queryParams ? url.concat(queryParams) : url
    location.replace(appendedUrl)
    // data analytics
    ;(globalThis as { [key: string]: any }).dataLayer.push({
      [tagName]: tagValue,
    })
  }

  return (
    <>
      <Grid
        container
        spacing={3}
        justifyContent="center"
        sx={{
          margin: '15px auto auto',
        }}
      >
        {dentureCardsData.map(card => (
          <Grid
            item
            key={card.id}
            justifyContent="center"
            alignItems="center"
            sx={{
              '.MuiPaper-root': {
                display: 'flex',
                flexDirection: contentFlow(card.id),
              },
              display: 'flex',
              width: mobileCards.cardWidth,
              pr: mobileCards.paddingRight,
            }}
          >
            <Card
              sx={{
                height: '100%',
                width: { xs: '450px', md: '200px', lg: '238px' },
                borderRadius: mobileCards.cardRadius,
                border: mobileCards.cardBorder,
                borderColor: 'tertiary.light',
                boxShadow: '0px 2px 2px rgba(73, 141, 240, 0.5)',
              }}
            >
              <Grid item>
                <CardHeader
                  title={
                    <TAGTypography
                      variant="bodySmallSemiBold"
                      color="tertiary.dark"
                      sx={{ display: 'flex', justifyContent: 'center', fontSize: '11px', lineHeight: '13px' }}
                    >
                      {card.header}
                    </TAGTypography>
                  }
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: 'warning.dark',
                    height: '20px',
                    py: mobileCards.headerPadding,
                    width: mobileCards.headerWidth,
                  }}
                />
                <CardMedia
                  image={isMobile ? card.imageMobile : card.image}
                  sx={{ height: mobileCards.imageHeight, width: '100%' }}
                />
              </Grid>
              <Grid
                item
                sx={{
                  backgroundColor: 'backgrounds.white',
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: '1',
                  justifyContent: 'space-between',
                }}
              >
                <CardContent
                  sx={{
                    backgroundColor: 'backgrounds.white',
                    ul: {
                      listStyle: 'none',
                      'li::before': { content: '"✓"' },
                      paddingInlineStart: '0px',
                      'li > .MuiTypography-root': {
                        ml: '5px',
                      },
                    },
                    '#CardTitle': { fontSize: '20px', lineHeight: '25px' },
                  }}
                >
                  <Grid item>
                    <TAGTypography
                      id="CardTitle"
                      variant="h2"
                      sx={{ textAlign: mobileCards.textAlignment, width: '90%', margin: 'auto' }}
                    >
                      {card.title}
                    </TAGTypography>
                  </Grid>
                  {isMobile ? (
                    <MobileDetails card={card} />
                  ) : (
                    <Grid item textAlign="center" sx={{ mt: '15px' }}>
                      <TAGTypography textAlign="center" variant="bodySmallBook" color="text.tertiary">
                        {card.description}
                      </TAGTypography>
                    </Grid>
                  )}
                </CardContent>
                <CardActions
                  sx={{
                    backgroundColor: 'backgrounds.white',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    alignItems: mobileCards.alignedItems,
                    a: { width: '100%' },
                  }}
                >
                  <TAGButton
                    buttonVariant="Secondary Default"
                    sx={{
                      maxWidth: '211px',
                      maxHeight: '47px',
                      fontSize: '11px',
                      lineHeight: '14px',
                      padding: 0,
                      width: '100%',
                      height: mobileCards.buttonHeight,
                      ml: isLarge ? '5px' : 'auto',
                    }}
                    onClick={() => onScheduleConsultationHandler(card.ctaUrl, card.cta_tag_name, card.cta_tag_value)}
                  >
                    {mobileCards.buttonText}
                  </TAGButton>
                </CardActions>
              </Grid>
              {!isMobile && (
                <Grid textAlign="center" sx={{ mb: '15px' }}>
                  <TAGTypography textAlign="center" sx={{ fontSize: '11px' }} color="text.tertiary">
                    Plan pricing varies pending on consult.
                  </TAGTypography>
                </Grid>
              )}
            </Card>
          </Grid>
        ))}
      </Grid>
      {isMobile ? (
        <CardsDialog id={activeStep} open={open} handleClose={handleClose} handleStepChange={handleStepChange} />
      ) : null}
    </>
  )
}

export default DentureCards
