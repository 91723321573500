import React, { FC } from 'react'
import { Card, CardContent, CardHeader, Grid, TAGTypography, useTheme, useMediaQuery } from '@aspendental/design-system'
import ClearChoiceDesktop from '../../images/desktop/clearchoice_white_logo.png'
import AspenDesktop from '../../images/desktop/aspen_white_logo.png'
import ClearChoiceMobile from '../../images/mobile/clearchoice_white_logo.png'
import AspenMobile from '../../images/mobile/aspen_white_logo.png'

export const CardRight: FC = () => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('md'))
  const clearChoiceLogo = isSmall ? ClearChoiceMobile : ClearChoiceDesktop
  const aspenLogo = isSmall ? AspenMobile : AspenDesktop

  const CardHeaderLogos = (
    <Grid
      container
      spacing={3}
      direction={isSmall ? 'column' : 'row'}
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item>
        <img src={clearChoiceLogo} alt="Clear choice logo" />
      </Grid>
      <Grid item>
        <img src={aspenLogo} alt="Aspen logo" />
      </Grid>
    </Grid>
  )

  const cardInfo = {
    cardTitle: 'Together, we are better',
    description:
      'ClearChoice and Aspen Dental are working together to help patients rediscover their smiles at convenient locations across the country, and right near you.',
  }
  const cardStyle = isSmall
    ? {
        width: '339px',
        height: '365px',
        titleSize: '24px',
        marginBottom: '10px',
        marginTop: '5px',
        headerHeight: '156px',
      }
    : {
        width: '410px',
        height: '354px',
        titleSize: '28px',
        marginTop: '10px',
        marginBottom: '10px',
        headerHeight: '123px',
      }

  return (
    <Grid container>
      <Grid item>
        <Card sx={{ width: cardStyle.width, height: cardStyle.height }}>
          <CardHeader
            title={CardHeaderLogos}
            sx={{
              padding: '0 20px',
              height: cardStyle.headerHeight,
              backgroundColor: 'backgrounds.dark',
              '.MuiCardHeader-title': {
                color: 'white',
              },
            }}
          />
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
              padding: '16px 35px',
            }}
          >
            <TAGTypography
              variant="h2"
              sx={{
                fontSize: isSmall ? '24px' : '28px',
                textAlign: 'center',
                color: 'text.primary',
                mb: cardStyle.marginBottom,
                mt: cardStyle.marginTop,
              }}
            >
              {cardInfo.cardTitle}
            </TAGTypography>
            <TAGTypography textAlign="center" variant={'bodyMediumBook'} sx={{ color: 'text.secondary' }}>
              {cardInfo.description}
            </TAGTypography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
export default CardRight
