import React, { FC } from 'react'
import Bain from '../components/Bain'

export const Home: FC = () => {
  return (
    <>
      {/* <h1>Hello from Wayne&apos;s World!</h1> */}
      <Bain />
    </>
  )
}

export default Home
