export const ClearChoicePolicyBody1 =
  'ClearChoice Dental Implant Centers are locally owned and operated by licensed dentists, and are part of a professional affiliation of implant practices operated by oral surgeons, prosthodontists, and restorative dentists across the U.S. A majority of patients (and approximately 90% of Minnesota patients) qualify for same day implants and can have their procedure in one day after initial workup, without additional bone graft surgery, and may leave the office that same day with provisional prostheses and the final prostheses to be placed later. Results may vary in individual cases. Some claims made are supported by network surveys. Both general dentists and specialists in some states. Dental implants are not a recognized specialty. Dental specialists are specialists in oral maxillofacial surgery and prosthodontics. Limited services available at satellite offices.'
export const ClearChoicePolicyBody2 =
  'Dental implants can last a lifetime. Studies show that dental implants are likely to last at least 25 years with proper care and maintenance in most cases'
export const ClearChoicePrivacyLink = 'https://ccstagingsite.kinsta.cloud/privacy-policy/'
export const ClearChoiceTermsLink = 'https://ccstagingsite.kinsta.cloud/terms/'

export const AspenPolicyBody1 =
  'Aspen Dental-branded dental practices are independently owned and operated by licensed dentists.'
export const AspenPolicyBody2 =
  'For more information about the relationship between Aspen Dental Management, Inc. and the branded dental practices '
export const AspenPracticeLink = 'https://www.aspendental.com/about'
export const AspenPrivacyLink = 'https://wwwstg.aspendental.com/privacy-policy'
export const AspenTermsLink = 'https://wwwstg.aspendental.com/terms-of-use'
