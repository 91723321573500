import React, { FC } from 'react'
import { Grid, TAGTypography, useTheme, useMediaQuery } from '@aspendental/design-system'

import DentureCards from './DentureCards'
import DescriptionCards from './DescriptionCards'

export const Hero: FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const mobileHero = isMobile
    ? { textAlignment: 'center', secondSpacing: '-10px' }
    : { textAlignment: 'left', secondSpacing: '5px' }
  return (
    <Grid
      container
      direction="column"
      sx={{ background: 'linear-gradient(179.96deg, #002D55 18%, #B9DEFA 33%, #FFFFFF 51%)' }}
    >
      <Grid container item justifyContent="center">
        <Grid item xs={9} md={12} textAlign="center" sx={{ mb: '15px', mt: '50px' }}>
          <TAGTypography variant="h1" color="text.light">
            Your leaders in smile replacement solutions
          </TAGTypography>
        </Grid>
      </Grid>
      <Grid container item justifyContent="center">
        <Grid
          item
          textAlign="center"
          xs={12}
          md={7}
          lg={5}
          sx={{ mx: '15px', mt: '0px', mb: mobileHero.secondSpacing }}
        >
          <TAGTypography variant="bodyMediumBold" color="text.light">
            Two providers, one network of expertise.
          </TAGTypography>
          <TAGTypography color="text.light">
            {' '}
            Together, ClearChoice Dental Implant Centers and Aspen Dental complete your smile so you can live with total
            confidence.
          </TAGTypography>
        </Grid>
        <DentureCards />
        <DescriptionCards />
      </Grid>
    </Grid>
  )
}

export default Hero
